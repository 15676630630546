*, ::before, ::after {
	box-sizing: border-box;
}

.material-symbols-outlined {
	font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 48;
	user-select: none;
}

button, summary, input[type="checkbox"] {
	cursor: pointer;
}

dialog {
	border: none;
	border-radius: .5rem;
	box-shadow: 0 0 1.5rem 0 hsl(var(--color-brand-hue) 0% 20% / 0.5);
}
